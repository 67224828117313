export const environment = {
  production: false,
  apiUrl: 'https://api.getcamber.com/multiplexer-cp-prod1/proterra/controlpanel/v1/',
  region: 'us-east-1',
  identityPoolId: '',
  userPoolId: 'us-east-1_ZtHE2WtXF',
  clientId: '23llqm36sies8t492pkjunitqq',
  cognito_idp_endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_ZtHE2WtXF',
  cognito_identity_endpoint: '',
  sts_endpoint: ''
};
